<template>
	<div :class="{'border-b border-gray-200': hasContentBelowMenu}">
		<div
			v-if="showLoading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 md:w-1/12" />
		</div>
		<template v-else>
			<CategoryQuickSelect
				v-if="hasCategories"
				class="w-full pb-2"
				:items="mapCategories(categories)"
				:list-type="CATEGORY_LIST"
				:item-count="categories.length"
				heading="Shop By Category"
				fade-color-from="from-gray-100"
				arrow-offset-classes="mb-16"
			/>
			<CategoryCarouselRow
				:name="name"
				:url="url"
				:categories="menuDataCategories"
				:indication="indication"
				:loading="loading"
			/>
			<template v-if="!menuData || loading">
				<div
					v-for="i in categoryCount"
					:key="i"
					class="flex flex-col w-full text-center border-b h-[370px] xl:h-[400px]"
				>
					<div class="w-full m-auto text-gray-300">
						Loading...
					</div>
					<LoadingSpinner class="w-1/12 m-auto text-gray-300" />
				</div>
			</template>
			<MenuEmptyState
				v-if="showEmptyState"
				class="flex flex-wrap justify-center h-full px-3 mt-6 mb-10"
				:business-type-url="typeUrl"
				:nearby-businesses="nearbyBusinesses"
				show-view-more-button
			/>
			<BusinessAbout class="px-3 pt-8" />
			<BusinessAboutReviews class="px-3 pt-8 pb-12 lg:pb-0" />
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

import { destructureNearbyBusiness } from '@/api/helpers/destructureNearbyBusiness.js'
import mapCategories from '@/api/helpers/mapCategories.js'
import MenuEmptyState from '@/components/business/BusinessEmptyMenu.vue'
import CategoryCarouselRow from '@/components/business/category-carousel-row/CategoryCarouselRow.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import { CATEGORY_LIST } from '@/constants/search/listTypes.js'
import { GetEmptyMenuData, GetMenuData } from '@/gql/queries/listing.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'
import CategoryQuickSelect from '@/views/city/components/carousel-rows/CityCarouselRow.vue'

export default {
	components: {
		MenuEmptyState,
		CategoryCarouselRow,
		CategoryQuickSelect,
		LoadingSpinner,
		BusinessAbout: () => import('@/components/business/about/BusinessAbout.vue'),
		BusinessAboutReviews: () => import('@/components/business/about/BusinessAboutReviews.vue')
	},
	data() {
		return {
			CATEGORY_LIST,
			loading: 0,
			isMounted: false
		}
	},
	computed: {
		...mapGetters('business', [ 'defaultIndication', 'hasMultipleIndications', 'businessId', 'categories' ]),
		hasCategories() {
			return !!this.categories?.length
		},
		name() {
			return this.menuData?.name || ''
		},
		url() {
			return this.menuData?.url || ''
		},
		typeUrl() {
			return this.emptyMenuData?.typeUrl || ''
		},
		indicationLoading() {
			return this.defaultIndication === ''
		},
		indication() {
			if (!this.hasMultipleIndications) return ''
			return this.$route.query?.indication || this.defaultIndication
		},
		menuDataCategories() {
			return this.menuData?.categories || []
		},
		categoryCount() {
			return this.categories?.length || 0
		},
		showEmptyState() {
			return !this.hasCategories && !this.loading && this.businessId
		},
		nearbyBusinesses() {
			return this.emptyMenuData?.nearbyListings?.map(listing =>
				destructureNearbyBusiness(listing)).slice(0, 3) || []
		},
		hasContentBelowMenu() {
			return !!this.menuData
		},
		showLoading() {
			return !this.hasCategories && this.isMounted && !this.showEmptyState
		}
	},
	apollo: {
		menuData: {
			query: GetMenuData,
			update(data) {
				if (data.meta) { this.$store.commit('setMeta', data.meta) }
				if (data.listing) {
					return {
						...data.listing
					}
				}
			},
			variables() {
				return {
					id: this.businessId,
					seoUrl: this.$route.params?.business,
					chainSeoUrl: this.$route.params?.chain,
					indication: this.indication || null
				}
			},
			skip() {
				return this.indicationLoading || !this.businessId || !this.hasCategories
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		},
		emptyMenuData: {
			query: GetEmptyMenuData,
			update(data) {
				if (data.listing) {
					return {
						...data.listing
					}
				}
			},
			variables() {
				return {
					id: this.businessId
				}
			},
			skip() {
				return this.hasCategories || !this.businessId
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		mapCategories
	}
}
</script>
