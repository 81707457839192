<template>
	<div
		id="business-index"
		:class="[
			{'pb-12 lg:pb-0': showCategoriesTab},
			'min-h-130'
		]"
	>
		<BusinessCategoryTab v-if="showCategoriesTab" />
		<BusinessMenuTab v-if="showMenuTab" />
	</div>
</template>

<script async>
import BusinessCategoryTab from '@/views/business/BusinessCategoryTab.vue'
import BusinessMenuTab from '@/views/business/BusinessMenuTab.vue'

export default {
	components: {
		BusinessCategoryTab,
		BusinessMenuTab
	},
	computed: {
		showCategoriesTab() {
			return !!this.$route.query?.category && !!Object.keys(this.$route.query?.category)?.length
		},
		showMenuTab() {
			return !this.showCategoriesTab
		}
	}
}
</script>
