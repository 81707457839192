<template>
	<div
		v-if="productCount > 0"
		class="relative md:pl-3 xl:pl-12 md:pt-4"
	>
		<div class="absolute top-0 -mt-44 md:-mt-16" />
		<h3 class="px-3 pt-3 text-lg font-bold uppercase md:px-0">
			{{ category }}
		</h3>
		<div>
			<TransitionGroup
				appear
				name="menu-slide-up"
				class="flex flex-col divide-y divide-gray-200 divide-solid"
			>
				<ProductCard
					v-for="(product, index) in products"
					:key="product.id"
					:product="destructureMenuProductGQL(product)"
					:style="{'--index': index, '--perPage': queryLimit, '--page': page}"
				/>
			</TransitionGroup>
		</div>
		<LoadingSpinner
			v-if="showLoadingSpinner"
			class="w-full h-24 text-gray-300"
		/>
	</div>
</template>

<script async>
import { destructureMenuProductGQL } from '@/api/helpers/destructureMenuProduct.js'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import ProductCard from '@/components/product/ProductCard.vue'

export default {
	components: {
		ProductCard,
		LoadingSpinner
	},
	props: {
		category: {
			type: String,
			required: true
		},
		products: {
			type: Array,
			required: true
		},
		queryLimit: {
			type: Number,
			default: 25
		},
		page: {
			type: Number,
			default: 1
		},
		showLoadingSpinner: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		productCount() {
			return this.products?.length
		}
	},
	methods: {
		destructureMenuProductGQL
	}
}
</script>
