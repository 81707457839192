<template>
	<div :class="{ 'pb-8': !hasCategories }">
		<div class="flex flex-wrap items-start pt-2 xl:px-3 xl:flex-nowrap xl:pb-6">
			<div class="sticky z-20 w-full xl:mb-2 top-[98px] lg:top-[58px] xl:top-[58px] xl:w-64">
				<!-- CART & FILTER BUTTON -->
				<div class="flex flex-wrap xl:pt-4">
					<div
						v-if="hasOrdering"
						class="flex-col w-full py-0 text-sm font-bold xl:py-2 xl:flex"
					>
						<div class="py-1.5 hidden xl:block">
							YOUR CART
						</div>
						<BusinessMenuCart
							:business-api-param="$route.params?.business"
							:cart="cart"
							:subtotal="subtotal"
							class="w-full bg-white"
						/>
					</div>
					<div class="text-sm font-bold py-1.5 hidden xl:block bg-white min-h-8">
						{{ itemCount }} PRODUCTS
					</div>
					<div class="w-full md:max-w-xl xl:max-w-none md:mx-auto">
						<div class="flex w-full px-3 py-2 space-x-2 bg-white xl:py-0 md:px-0 ">
							<div class="relative flex content-center w-full">
								<WwTextInput
									id="search-query"
									v-model="searchQuery"
									class="w-full"
									border-class="border"
									input-class="self-center w-full h-10 transition-all duration-100 ease-in-out bg-white border rounded-md shadow-sm xl:h-12 hover:shadow pl-9 focus:ring focus:ring-green-300 ring-offset-2"
									placeholder="Search in Menu..."
								/>
								<MagnifierIcon
									height="15"
									width="15"
									class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-5"
								/>
							</div>
							<div class="flex items-center justify-end xl:hidden">
								<button
									class="items-center justify-center w-10 h-10 transition-all duration-100 ease-in-out bg-white border border-gray-300 rounded-full shadow-sm outline-none hover:shadow focus:ring focus:ring-green-300 ring-offset-2"
									@click="showModal(MENU_FILTER_MODAL)"
								>
									<img
										src="@/assets/icons/filters-circle.svg"
										class="h-full rounded-full"
										height="40"
										width="40"
										alt="Filters"
									>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="hidden w-full py-2 xl:block">
					<BusinessMenuFilters
						v-if="hasMenuFilters"
						ref="filters"
						:menu-filters="menuFilters"
						:total-items-count="itemCount"
						:sub-category-name="filters.category || ''"
					/>
				</div>
			</div>
			<div class="flex-1 md:max-w-xl md:mx-auto xl:mx-0 xl:max-w-none">
				<template v-if="showPlaceholderCards">
					<PlaceholderCard
						v-for="i in 4"
						:key="i"
					/>
				</template>
				<div
					v-if="showProducts"
					class="flex flex-col w-full divide-y divide-gray-200 divide-solid"
				>
					<ProductCategory
						:category="filters.category"
						:products="menu.products"
						:page="page"
						:query-limit="BUSINESS_MENU_QUERY_LIMIT"
						:show-loading-spinner="showLoadingSpinner"
					/>
				</div>
				<div
					v-else-if="showEmptyState"
					class="pt-6 pb-8 xl:pt-16 empty-search-transition"
				>
					<Transition
						appear
						name="fade"
					>
						<p class="text-xl font-bold text-center">
							Your search returned no results.
						</p>
					</Transition>
				</div>
			</div>
		</div>
		<BusinessMenuFilterModal
			v-if="hasMenuFilters"
			:menu-filters="menuFilters"
			:total-items-count="itemCount"
			:sub-category-name="filters.category || ''"
		/>
		<CategoryQuickSelect
			v-if="hasCategories"
			class="w-full py-2"
			:items="mapCategories(categories)"
			:list-type="CATEGORY_LIST"
			:item-count="categories.length"
			heading="Shop By Category"
			fade-color-from="from-gray-100"
			arrow-offset-classes="mb-16"
		/>
	</div>
</template>

<script async>
import { mapGetters, mapMutations, mapState } from 'vuex'

import mapCategories from '@/api/helpers/mapCategories.js'
import BusinessMenuCart from '@/components/business/BusinessMenuCart.vue'
import MagnifierIcon from '@/components/icons/MagnifierIcon.vue'
import PlaceholderCard from '@/components/multiUse/PlaceholderCard.vue'
import ProductCategory from '@/components/product/ProductCategory.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { ADD_TO_CART_MODAL, MENU_FILTER_MODAL } from '@/constants/modal/names.js'
import { CATEGORY_LIST } from '@/constants/search/listTypes.js'
import { GetListingProducts } from '@/gql/queries/listing.gql'
import { logError } from '@/utils/error-handling.js'

const BUSINESS_MENU_QUERY_LIMIT = 24

export default {
	components: {
		PlaceholderCard,
		ProductCategory,
		BusinessMenuFilters: () => import('@/components/business/BusinessMenuFilters.vue'),
		BusinessMenuFilterModal: () => import('@/components/UI/modals/BusinessMenuFilterModal.vue'),
		CategoryQuickSelect: () => import('@/views/city/components/carousel-rows/CityCarouselRow.vue'),
		MagnifierIcon,
		WwTextInput,
		BusinessMenuCart
	},
	data() {
		return {
			loading: 0,
			page: 1,
			MENU_FILTER_MODAL,
			BUSINESS_MENU_QUERY_LIMIT,
			searchQuery: '',
			CATEGORY_LIST,
			observed: false,
			observer: null,
			debounceTimer: null,
			debouncedSearchQuery: null,
			enableLoadingPlaceholder: false
		}
	},
	apollo: {
		menu: {
			query: GetListingProducts,
			update(data) {
				if (data) {
					return {
						products: data.listing?.products,
						menuFilters: data.listing?.menuFilters,
						settings: data.listing?.settings,
						categories: data.listing?.categories,
						paging: data.paging
					}
				}
			},
			variables() {
				return {
					id: this.businessId,
					seoUrl: this.$route.params.business,
					chainSeoUrl: this.$route.params.chain || '',
					category: this.filters.category,
					// brand: this.filters.brand?.replaceAll('%20', ' ') || null,
					subCategory: this.filters.subCategory,
					// genetic: this.filters.genetics,
					price: this.filters.price,
					indication: this.indication,
					name: this.searchQueryArgument
				}
			},
			error(error) {
				logError(error)
			},
			skip() {
				return !this.businessId
			},
			errorPolicy: 'all'
		}
	},
	computed: {
		...mapState('modal', [
			'isOpen',
			'activeModalId'
		]),
		...mapGetters('business', [ 'defaultIndication', 'hasMultipleIndications', 'businessId' ]),
		toastSecondaryText() {
			let text = `${this.cart.lastAddedItem.name}`
			if (this.cart.lastAddedItem.quantity > 1) {
				text = text.concat(` (x${this.cart.lastAddedItem.quantity})`)
			}
			return text
		},
		itemCount() {
			return this.menu?.categories.find(category => category.name === this.filters?.category)?.itemsCount || 0
		},
		subtotal() {
			if (this.cart.subtotal !== undefined) {
				return this.cart.subtotal.toFixed(2)
			} else {
				return 0
			}
		},
		...mapGetters('cart', [ 'cart' ]),
		menuFilters() {
			if (this.menu?.menuFilters) return Object.assign(this.menu.menuFilters, { subCategories: this.currentSubCategories })
			return {}
		},
		hasMenuFilters() {
			return Object.keys(this.menuFilters).length
		},
		hasOrdering() {
			return this.cart?.settings?.hasOrdering
		},
		filters() {
			return this.$route.query
		},
		totalProducts() {
			return this.menu?.products?.length || 0
		},
		hasProducts() {
			return !!this.totalProducts
		},
		showProducts() {
			return this.hasProducts
		},
		showEmptyState() {
			return !this.loading && !this.hasProducts && this.enableLoadingPlaceholder
		},
		showPlaceholderCards() { // show loading animation only on initial load when there are no products, OR when waitng for the search query to return
			return this.enableLoadingPlaceholder && !this.hasProducts && !this.showEmptyState || !!this.searchQueryArgument?.length && !this.hasProducts && this.loading
		},
		showLoadingSpinner() {
			return !!(this.loading && this.showProducts)
		},
		observerIndex() {
			return this.totalProducts - (BUSINESS_MENU_QUERY_LIMIT / 2) || 0
		},
		categories() {
			return this.menu?.categories || []
		},
		hasCategories() {
			return !!this.categories?.length
		},
		currentCategory() {
			return this.menu?.categories?.find(category => category.name === this.filters?.category) || {}
		},
		currentSubCategories() {
			return this.currentCategory?.subCategories
		},
		productsOffset() {
			return this.totalProducts
		},
		indication() {
			if (!this.hasMultipleIndications) return null
			return this.filters?.indication || this.defaultIndication
		},
		searchQueryArgument() {
			return this.searchQuery?.length > 2 ? this.searchQuery : null
		}
	},
	watch: {
		'cart.lastAddedItem'(item, previousItem) {
			if (item === previousItem || !this.cart.lastAddedItem.name) return
			this.showModal(ADD_TO_CART_MODAL)
		},
		menu() {
			if (this.menu?.paging?.products?.resultsCount > this.productsOffset) {
				this.observeProducts()
			}
		},
		observed() {
			if (this.observed) {
				this.page++
				this.fetchMoreProducts()
				this.observed = false
			}
		},
		searchQueryArgument(newValue) {
			this.enableLoadingPlaceholder = true
			if (this.debounceTimer) {
				clearTimeout(this.debounceTimer)
			}
			this.debounceTimer = setTimeout(() => {
				this.setDebouncedSearchQuery(newValue)
			}, 500)
		},
		loading(newValue) {
			if (newValue) this.enableLoadingPlaceholder = true
		}
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
		clearTimeout(this.debounceTimer)
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		resetFilters() {
			this.$router.push({ query: { category: this.$route.query.category } })
		},
		mapCategories,
		observeProducts() {
			this.$nextTick(() => {
				if (window && 'IntersectionObserver' in window) {
					const scrollTarget = document.getElementById(this.menu.products?.[this.observerIndex]?.id)
					if (scrollTarget) {
						this.observer = new IntersectionObserver(entries => {
							entries.forEach(entry => {
								if (entry.isIntersecting) {
									this.observed = true
									this.observer.disconnect()
								}
							})
						})
						this.observer.observe(scrollTarget)
					}
				} else {
					this.observed = true
				}
			})
		},
		async fetchMoreProducts() {
			try {
				await this.$apollo.queries.menu.fetchMore({
					variables: {
						offset: this.productsOffset
					},
					updateQuery(previousResult, { fetchMoreResult }) {
						if (!fetchMoreResult?.listing?.products?.length) {
							return previousResult
						}
						return {
							__typename: previousResult?.listing.__typename,
							listing: {
								__typename: previousResult?.listing.__typename,
								id: previousResult?.listing.id,
								categories: previousResult?.listing.categories,
								menuFilters: previousResult?.listing.menuFilters,
								products: [ ...previousResult?.listing.products, ...fetchMoreResult?.listing.products ]
							},
							paging: {
								__typename: previousResult.paging.__typename,
								products: {
									__typename: previousResult.paging.products.__typename,
									resultsCount: fetchMoreResult.paging.products.resultsCount,
									offset: fetchMoreResult.paging.products.offset,
									limit: fetchMoreResult.paging.products.limit
								}
							}
						}
					}
				})
			} catch (error) {
				logError(error)
			}
		},
		setDebouncedSearchQuery(input) {
			this.debouncedSearchQuery = input
		}
	}
}
</script>
<style lang="scss" scoped>

$menu-sticky-top-offset-md: -68px;
$menu-sticky-top-offset-lg: -20px;

.empty-search-transition {
	.fade-enter-active {
		transition: all 0.35s ease-out;
		transition-delay: 0.13s
	}
	.fade-leave-active {
		transition: all 0.35s ease-out;
	}
	.fade-enter,
	.fade-leave-to {
		opacity: 0;
		transform: translateY(35px);
	}
}
</style>
