<template>
	<router-link
		v-if="product.active"
		:to="productRoute"
		class="relative pt-6 pb-4 bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
	>
		<div
			:id="product.id"
			class="absolute left-0 top-[-150px] xl:-top-14"
		/>
		<div
			class="flex min-w-[280px]"
			:class="{ disabled : orderingDisabled }"
		>
			<div class="flex-shrink-0 w-20 px-0 ml-3 border border-gray-300 rounded-l xl:w-32 max-h-20 xl:max-h-32 xl:mx-0">
				<WwImage
					:src="product.image"
					classes="rounded-l"
					object-cover
					:alt="product.name"
					:width="130"
					:height="130"
					:image-path="MENU_IMAGES"
				/>
			</div>
			<section class="flex flex-col text-sm min-h-20 xl:min-h-32">
				<h4 class="w-full px-4 text-lg font-semibold text-black xl:px-3 line-clamp-2">
					{{ product.name }}
				</h4>
				<div class="text-gray-500 min-h-5">
					<div class="xl:px-3 xl:space-x-1 xl:flex">
						<div
							v-if="hasBrandName"
							class="lg:max-w-[50%] px-4 mr-1 font-bold xl:w-auto xl:px-0 min-h-4 line-clamp-2 shrink-0"
						>
							{{ product.brand }}
						</div>
						<div class="px-4 mt-auto xl:mt-0 xl:px-0">
							<div class="block mt-3 xl:mt-0">
								<span
									v-if="hasSpecies"
									class="capitalize"
								>
									{{ product.species }} <span v-if="hasSpeciesAndComposition">
										-
									</span>
								</span>
								<span v-if="hasPharmaComposition">
									{{ compositionBreakdown }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<!-- START Desktop Pricing -->
				<ProductPricing
					v-if="hasPricing"
					:product="product"
					:indication="pricingIndication"
					:business-id="businessId"
					:ordering-disabled="orderingDisabled"
					fade-visibility="hidden"
					scroller-class="hidden py-2 pr-2 mt-3 xl:flex"
					class="mt-auto"
				/>
				<!-- END Desktop Pricing -->
			</section>
		</div>
		<!-- START Mobile Pricing -->
		<ProductPricing
			v-if="hasPricing"
			:product="product"
			:indication="pricingIndication"
			:business-id="businessId"
			:ordering-disabled="orderingDisabled"
			fade-visibility="hidden"
			scroller-class="py-2 pt-6 pl-1 pr-3 md:pr-2 md:pl-0"
			class="xl:hidden"
		/>
		<!-- END Mobile Pricing -->
	</router-link>
</template>

<script async>
import { mapGetters } from 'vuex'

import ProductPricing from '@/components/product/ProductPricing.vue'
import { INDICATION_TYPES } from '@/constants/business/index.js'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import sanitizePharmaComposition from '@/utils/mappers/sanitize-pharma-composition.js'
import { getPricePerUnit } from '@/utils/pricing.js'
import getProductRouteName from '@/utils/product/get-route-name.js'

const { RECREATIONAL, MEDICAL } = INDICATION_TYPES
export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue'),
		ProductPricing
	},
	props: {
		product: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			itemBeingAddedToCart: null,
			sizesInCart: [],
			MENU_IMAGES
		}
	},
	computed: {
		...mapGetters('business', [ 'defaultIndication' ]),
		indication() {
			return this.$route.query?.indication || this.defaultIndication || null
		},
		businessId() {
			return this.product?.listingId || 0
		},
		hasBrandName() {
			return !!this.product?.brand?.length
		},
		hasSpecies() {
			return !!this.product?.species?.length
		},
		hasPharmaComposition() {
			return !!this.product?.pharmaComposition?.length
		},
		hasSpeciesAndComposition() {
			return this.hasSpecies && this.hasPharmaComposition
		},
		pricing() {
			return getPricePerUnit({
				product: this.product,
				indication: this.productIndication,
				skipValidation: this.indicationMismatch
			})
		},
		productIndication() {
			return this.product?.pricing?.[0]?.type || this.product?.pricingOld?.[0]?.type
		},
		pricingIndication() {
			if (this.indicationMismatch) return this.productIndication
			return this.indication
		},
		indicationMismatch() {
			const isCurrentIndication = this.productIndication === this.indication

			const isMedicalProduct = this.productIndication === MEDICAL.value
			const isValidMedicalProduct = isMedicalProduct && isCurrentIndication

			const isRecreationalProduct = this.productIndication === RECREATIONAL.value
			const isValidRecreationalProduct = isRecreationalProduct && isCurrentIndication

			return !isValidMedicalProduct && !isValidRecreationalProduct
		},
		hasPricing() {
			return !!this.pricing?.length
		},
		productRoute() {
			return {
				name: getProductRouteName(this.$route),
				params: {
					...this.$route.params,
					productId: this.product?.id
				}
			}
		},
		orderingDisabled() {
			return (!this.product?.hasOrdering || !this.hasPricing) || this.indicationMismatch || false
		},
		compositionBreakdown() {
			return sanitizePharmaComposition(this.product?.pharmaComposition).map(({ label, amount }) => { return `${label.toUpperCase()}: ${amount}%` }).join(' | ')
		}
	}
}
</script>
