<template>
	<div
		:ref="CAROUSEL_ITEM"
		class="flex-none border border-gray-300 shadow-lg cursor-pointer w-[11rem] h-[17rem] rounded-xl hover:shadow-xl"
	>
		<router-link
			:to="productRoute"
			class="hover:text-black"
		>
			<div class="relative bg-white border-b border-gray-300 h-3/5 rounded-t-xl">
				<WwImage
					:src="imageFile"
					classes="rounded-t-xl"
					object-cover
					:alt="product.name"
					:fallback-image="DEFAULT_WW_GRAY_LOGO"
					:width="220"
					:height="220"
					:image-path="MENU_IMAGES"
				/>
			</div>
			<div class="grid grid-cols-1 px-3 py-4 h-2/5 place-content-between">
				<div class="flex font-bold text-md line-clamp-1">
					{{ product.name }}
				</div>
				<div class="flex text-sm capitalize line-clamp-1">
					{{ product.subCategory.name }}
				</div>
				<div class="flex h-5 text-sm">
					<div v-if="priceDisplayed">
						<span class="mr-1 font-bold text-green-500">
							${{ priceDisplayed }}
						</span>
						{{ sizeDisplayed }}
						<span
							v-if="showPlusIcon"
							class="ml-1"
						>
							+
						</span>
					</div>
				</div>
			</div>
		</router-link>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { INDICATION_TYPES } from '@/constants/business/index.js'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { getPrice } from '@/utils/pricing.js'
import getProductRouteName from '@/utils/product/get-route-name.js'

const { RECREATIONAL, MEDICAL } = INDICATION_TYPES

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		product: {
			type: Object,
			default: () => ({})
		},
		indication: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			DEFAULT_WW_GRAY_LOGO,
			CAROUSEL_ITEM,
			RECREATIONAL,
			MEDICAL,
			MENU_IMAGES
		}
	},
	computed: {
		...mapGetters('business', [ 'hasMultipleIndications' ]),
		imageFile() {
			return this.product?.image || false
		},
		price() {
			return getPrice({
				product: this.product,
				indication: this.indication
			})
		},
		showPlusIcon() {
			return this.price?.length > 1
		},
		priceDisplayed() {
			if (this.price?.length) {
				return this.price.find(({ amount }) => amount > 0)?.amount
			}
			return ''
		},
		sizeDisplayed() {
			if (this.showPlusIcon) return this.price?.[0]?.label
			return 'ea'
		},
		productRoute() {
			return {
				name: getProductRouteName(this.$route),
				params: {
					...this.$route.params,
					productId: this.product.id,
					seoUrl: this.product.seoUrl
				}
			}
		}
	}
}
</script>
