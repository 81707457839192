<template>
	<div class="divide-y divide-gray-200">
		<div
			v-for="category in categoriesWithProducts"
			:key="category.id"
		>
			<div class="w-full xl:pb-8">
				<WwCarousel
					:id="category.id"
					:key="category.id"
					:item-count="category.products.length"
					:items-per-slide="5"
					item-spacing="space-x-3 xl:space-x-6"
					side-scroller-class="px-3 pb-8"
					scrollbar-visibility="xl:hide-horizontal-scrollbar"
					arrow-visibility="hidden xl:block"
					fade-visibility="hidden xl:block"
					footer-class="hidden py-6 xl:flex"
					gradient-color-from="from-white"
					:fade-use-row-item-height="false"
					:loading="loading"
				>
					<template #header>
						<div class="flex items-center w-full px-3 py-4 space-x-2 flex-nowrap">
							<span class="font-bold text-left uppercase truncate">
								{{ category.name }}
							</span>
							<span class="pl-1 mb-1 text-xl text-gray-300">
								|
							</span>
							<router-link
								:title="`View All ${category.name} at ${name}`"
								class="flex w-auto pl-1 font-bold text-green-500 uppercase flex-nowrap hover:text-purple-500"
								:to="{
									path: url,
									query: { category: category.name }
								}"
							>
								<span class="w-20 flex-nowrap">
									View All
								</span>
							</router-link>
						</div>
					</template>
					<template #slides>
						<CarouselRowItem
							v-for="(product, index) in category.products"
							:key="'card-' + index"
							:product="destructureMenuProductGQL(product)"
							:indication="indication"
						/>
					</template>
				</WwCarousel>
				<div class="w-full max-w-6xl px-3 mx-auto" />
			</div>
		</div>
	</div>
</template>

<script defer>
import { destructureMenuProductGQL } from '@/api/helpers/destructureMenuProduct.js'
import CarouselRowItem from '@/components/business/category-carousel-row/CarouselRowItem.vue'
import WwCarousel from '@/components/UI/WwCarousel.vue'
import { FLOWER, HYBRID, INDICA, SATIVA } from '@/constants/carousel/flower-sub-types.js'
import {
	CONCENTRATES_CATEGORY,
	EDIBLES_CATEGORY,
	FLOWER_CATEGORY,
	OTHER_CATEGORY,
	PREROLLS_CATEGORY
} from '@/constants/carousel/menu-categories.js'
import { CLONES, MERCHANDISE, OTHER, SEEDS, TINCTURE } from '@/constants/carousel/other-sub-types.js'

export default {
	components: {
		WwCarousel,
		CarouselRowItem
	},
	props: {
		name: {
			type: String,
			default: ''
		},
		url: {
			type: String,
			default: ''
		},
		categories: {
			type: Array,
			required: true
		},
		indication: {
			type: String,
			required: true
		},
		loading: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			categoryRows: [
				FLOWER_CATEGORY,
				CONCENTRATES_CATEGORY,
				EDIBLES_CATEGORY,
				PREROLLS_CATEGORY,
				OTHER_CATEGORY
			],
			flowerSubTypes: [ FLOWER, SATIVA, INDICA, HYBRID ],
			otherSubTypes: [
				MERCHANDISE,
				SEEDS,
				CLONES,
				TINCTURE,
				OTHER
			]
		}
	},
	computed: {
		categoriesWithProducts() {
			return this.categories.filter(category => !!category.products?.length).sort((category1, category2) => {
				return category1?.position - category2?.position
			})
		}
	},
	methods: {
		destructureMenuProductGQL
	}
}
</script>
