import { CATEGORY_LIST } from '@/constants/search/listTypes.js'
import { CATEGORY } from '@/constants/search/resultTypes.js'

const imagePaths = {
	flower: require('@/assets/categories/flower.jpg'),
	concentrates: require('@/assets/categories/concentrate.jpg'),
	edibles: require('@/assets/categories/edibles.jpg'),
	prerolls: require('@/assets/categories/preroll.jpg'),
	other: require('@/assets/categories/other.jpg'),
	topicals: require('@/assets/categories/topical.jpg'),
	vaporizers: require('@/assets/categories/vape.jpg'),
	tincture: require('@/assets/categories/tincture.jpg'),
	merchandise: require('@/assets/categories/merchandise.jpg'),
	seeds: require('@/assets/categories/seeds.jpg'),
	sativa: require('@/assets/categories/sativa.jpg'),
	indica: require('@/assets/categories/indica.jpg'),
	hybrid: require('@/assets/categories/hybrid.jpg')
}

export default function mapCategories(categories) {
	return categories.map(({ name }) => ({
		label: name.toUpperCase(),
		category: CATEGORY,
		filter: name,
		image: imagePaths[name],
		listType: CATEGORY_LIST,
		link: { query: { category: name } }
	}))
}
