<template>
	<button
		aria-label="Cart-Info"
		class="fixed bottom-0 w-full xl:relative"
		@click.prevent="showSlideOver(CART_SLIDE_OVER)"
	>
		<fieldset
			v-if="showLeftSidebar"
			class="hidden w-full p-2.5 text-sm border border-gray-300 h-12 rounded-md xl:block hover:text-green-500 shadow-sm hover:shadow"
		>
			<div class="flex items-center w-full h-full">
				<img
					src="@/assets/icons/cart.svg"
					alt="Cart"
					height="18"
					width="20"
				>
				<span class="pl-3">
					{{ numItemsDisplay }}
				</span>
				<span class="ml-auto">
					${{ subtotal }}
				</span>
			</div>
		</fieldset>
	</button>
</template>

<script async>
import { mapMutations } from 'vuex'

import { CART_SLIDE_OVER } from '@/constants/slideOver/names.js'

export default {
	props: {
		businessApiParam: {
			type: String,
			required: true
		},
		cart: {
			type: Object,
			required: true
		},
		subtotal: {
			type: String,
			required: true
		},
		showLeftSidebar: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			CART_SLIDE_OVER
		}
	},
	computed: {
		numItemsDisplay() {
			return `${this.cart.num_items} item${!this.isOneItem ? 's' : ''}`
		},
		isOneItem() {
			return this.cart.num_items === 1
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver' ])
	}
}
</script>
